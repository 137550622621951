import Vue from 'vue'
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: window.config.apiBaseUrl,
})
axiosIns.interceptors.response.use(
  response => response,
  error => (axios.isCancel(error) ? new Promise(() => { }) : Promise.reject(error)),
)

Vue.prototype.$http = axiosIns

export default axiosIns
