import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import calendar from './calendar'
import chart from './chart'
import oidc from './oidc' // eslint-disable-line
import verticalMenu from './vertical-menu'
import users from './users'
import initialData from './initial-data'
import youngs from '../views/youngs/youngsStoreModule'
import events from './events'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    calendar,
    chart,
    initialData,
    oidc,
    verticalMenu,
    users,
    youngs,
    events,
  },
  strict: process.env.DEV,
})
