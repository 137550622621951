import Vue from 'vue'
import jwtDecode from 'jwt-decode'
import axiosIns from '@/libs/axiosIns'

export default {
  namespaced: true,
  state: {
    users: [],
    historyCall: [],
    callRecording: [],
    userRole: '',
  },
  getters: {
    getUser: (state, getters) => id => {
      if (!id) { return null }
      const user = getters.users.find(u => u.id === id)
      return user ?? {
        id, givenName: '?', familyName: '?', userName: 'Inconnu',
      }
    },
    getUserFullName: (state, getters) => id => `${getters.getUser(id).familyName} ${getters.getUser(id).givenName}`,
    users: state => state.users,
    getHistoryCall: state => state.historyCall,
    getCallRecording: state => state.callRecording,
    getUserRole: state => ((typeof state.userRole === 'string') ? state.userRole : state.userRole.find(e => e.startsWith('LeRefuge'))),
  },
  mutations: {
    fetchUser(state, users) {
      state.users = users
    },
    fetchHistoryCall(state, historyCall) {
      state.historyCall = state.historyCall.concat(historyCall)
    },
    resetHistoryCall(state) {
      state.historyCall = []
    },
    fetchCallRecording(state, callRecording) {
      state.callRecording = callRecording
    },
    setRole(state, token) {
      state.userRole = jwtDecode(token)
      state.userRole = state.userRole.role
    },
    addUserMutation(state, users) {
      state.users.push(users)
    },
    updateUsersForm(state, users) {
      const index = state.users.findIndex(e => e.id === users.id)
      Vue.set(state.users, index, users)
    },
    updateListenBy(state, historyCallUpdated) {
      const listenBy = {
        userFirstName: historyCallUpdated.userFirstName,
        userLastName: historyCallUpdated.userLastName,
        userId: historyCallUpdated.userId,
        date: historyCallUpdated.date,
      }
      const index = state.historyCall.findIndex(e => e.id === historyCallUpdated.callId)
      state.historyCall[index].listenBys.push(listenBy)
    },
    deleteCallVoicemail(state, id) {
      const index = state.historyCall.findIndex(e => e.id === id)
      state.historyCall.splice(index, 1)
    },
  },
  actions: {
    fetchUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axiosIns.get('User')
          .then(response => {
            commit('fetchUser', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchHistoryCall({ commit }, data) {
      return new Promise((resolve, reject) => {
        if (data.PageNumber === 1) { commit('resetHistoryCall') }
        axiosIns.get(`User/CallVoicemail?PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`)
          .then(response => {
            commit('fetchHistoryCall', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCallRecording({ commit }) {
      return new Promise((resolve, reject) => {
        axiosIns.get('User/CallRecordings')
          .then(response => {
            commit('fetchCallRecording', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('User/Add', data)
          .then(response => {
            commit('addUserMutation', data)
            resolve(response)
          })
        .catch(error => reject(error))
      })
    },
    updateUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.put('User/Update', data)
          .then(response => {
            commit('updateUsersForm', data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateListenBy({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.put('User/UpdateListenBy', data)
          .then(response => {
            commit('updateListenBy', data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteCallVoicemail({ commit }, id) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`User/DeleteCallVoicemail/${id}`)
          .then(response => {
            commit('deleteCallVoicemail', id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    anonymizeYoung({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`User/AnonymizeYoung/${data}`)
          .then(response => {
            resolve(response)
            commit('youngs/updateAnonymizeUser', data, { root: true })
            })
          .catch(error => reject(error))
      })
    },
  },
}
