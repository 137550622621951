import router from '@/router' // eslint-disable-line
import store from '@/store' // eslint-disable-line
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import axiosIns from '@/libs/axiosIns'

const oidcConfig = window.config.authentication
const oidcSettings = {
  authority: oidcConfig.authority,
  clientId: oidcConfig.clientId,
  redirectUri: `${window.location.origin}/signin-oidc`,
  postLogoutRedirectUri: window.location.origin,
  silentRedirectUri: `${window.location.origin}/silent-renew-oidc.html`,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  loadUserInfo: false,
  responseType: 'code',
  scope: `${oidcConfig.scope}`,
}

if (oidcConfig.resource) {
  oidcSettings.extraQueryParams = {
    resource: oidcConfig.resource,
  }
}

export default vuexOidcCreateStoreModule(oidcSettings, { namespaced: true },
  {
    userLoaded: user => {
      axiosIns.defaults.headers.common.Authorization = `Bearer ${user.access_token}`
      store.commit('users/setRole', user.access_token)
      store.dispatch('users/fetchUsers')
      store.dispatch('initialData/fetchInitialData')
    },
    userUnloaded: () => {
      axiosIns.defaults.headers.common.Authorization = ''
      router.go(0)
    },
    userSignedOut: () => {
      store.dispatch('oidc/removeOidcUser')
    },
    silentRenewError: () => console.error('Silent renew failed'),
    oidcError: payload => console.error(`An error occured at ${payload.context}:`, payload.error),
    automaticSilentRenewError: payload => console.error('Automatic silent renew failed', payload.error),
  })
